<template>
  <!-- 套餐中心 -->
  <div class="setMeal_box">
    <app-container :title="'套餐中心'" :background="tool.getThemeList(theme)">
      <div class="usage-record">
        <van-tabs
          v-model:active="activeName"
          @click="selectType"
          title-inactive-color="#AAAAAA"
          :title-active-color="tool.getThemeList(theme)"
        >
          <van-tab title="套餐详情" name="package" />
          <van-tab title="用量详情" name="dosage" />
        </van-tabs>
        <div class="usage-box">
          <canvas id="chartCanvas" v-show="activeName == 'dosage' && dataList_.length > 0"></canvas>
          <div :class="{ dosage: activeName == 'dosage' }">
            <!-- 套餐详情 -->
            <div v-if="activeName == 'package'">
              <div v-if="dataList.length > 0">
                <div
                  class="card-item"
                  v-for="(item, index) in dataList"
                  :key="index"
                >
                  <van-collapse v-model="activeNames" :accordion="true">
                    <van-collapse-item :name="index">
                      <template #title>
                        <div class="card-head_">
                          <i
                            class="icon"
                            :class="{
                              package_1: item.name === '生效中套餐',
                              package_2: item.name === '待生效套餐',
                              package_3: item.name === '已失效套餐',
                              package_4: item.name === '续费中'
                            }"
                          ></i>
                          <h3>{{ item.name }}</h3>
                        </div>
                      </template>
                      <!-- 流量详情 -->
                      <div v-if="item.values.flowDetails" class="card-content">
                        <div
                          class="card-box"
                          v-for="(item_, index_) in item.values.flowDetails"
                          :key="index_"
                        >
                          <div class="card-row">
                            <ul class="card-list">
                              <li>
                                <div class="top-text">
                                  <span style="color: #000;">{{ item_.packageName }}</span>
                                  <template v-if="userInfo.labels.includes(1) && item_.type == 2"></template>
                                  <span v-else-if="item_.totalFlow !== null && flowStatus != 'used' && theme != 'AnFang'"
                                    >总量{{ item_.totalFlow }}MB</span
                                  >
                                </div>
                                <!-- 判断显示有效期（套餐备注信息） 订购时间 -->
                                <template v-if="userInfo.labels.includes(4)">
                                  <div class="validity-box">
                                    <div>订购时间：{{item_.buyTime}}</div>
                                    <div v-if="item_.hiddenRemarks ">有效期：{{item_.hiddenRemarks}}</div>
                                  </div>
                                </template>
                                <template v-if="userInfo.labels.includes(1) && item_.type == 2"></template>
                                <template v-else>
                                  <div v-if="showProgressFlow(item_)">
                                    <van-progress
                                      :percentage="
                                        item_.totalFlow > 0
                                          ? (
                                              item_.useFlow / item_.totalFlow
                                            ).toFixed(2) * 100
                                          : 0
                                      "
                                      :color="
                                        item_.useFlow / item_.totalFlow >= 0.9
                                          ? 'red'
                                          : item_.useFlow / item_.totalFlow >=
                                              0.8 &&
                                            item_.useFlow / item_.totalFlow < 0.9
                                          ? 'yellow'
                                          : tool.getThemeList(theme)
                                      "
                                      stroke-width="10"
                                    />
                                  </div>
                                  <div
                                    class="bottom-text"
                                    :class="{
                                      bottomText:
                                        item_.effectiveTime === null &&
                                        item_.deadTime === null
                                    }"
                                  >
                                    <span v-if="item_.virtualPeriod">
                                      {{ item_.virtualPeriod }}
                                    </span>
                                    <span
                                      v-else-if="showEffectiveTimeShutDown(item_)"
                                      >{{ item_.effectiveTime.slice(0, 11) }} 到 {{
                                        item_.deadTime.slice(0, 11)
                                      }}
                                    </span>
                                    <span v-if="item_.renewalStatus == 1"
                                      >套餐正在受理中，请稍等</span
                                    >
                                    <span
                                      style="color: #f37f00"
                                      v-if="item_.totalFlow !== null && flowStatus != 'used' && item_.renewalStatus != 1 && theme != 'AnFang'"
                                      >剩余{{
                                        (item_.totalFlow - item_.useFlow).toFixed(
                                          2
                                        )
                                      }}MB</span
                                    >
                                  </div>
                                </template>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- 语音详情-->
                      <div v-if="item.values.voiceDetails" class="card-content">
                        <div
                          class="card-box"
                          v-for="(item_, index_) in item.values.voiceDetails"
                          :key="index_"
                        >
                          <div class="card-row">
                            <ul class="card-list">
                              <li>
                                <div class="top-text">
                                  <span>{{ item_.packageName }}</span>
                                  <span v-if="item_.totalVoice !== null && theme != 'AnFang'"
                                    >总语音{{ item_.totalVoice }}分钟</span
                                  >
                                </div>
                                <div v-if="showProgressVoice(item_)">
                                  <van-progress
                                    :percentage="
                                      item_.totalVoice > 0
                                        ? (
                                            item_.useVoice / item_.totalVoice
                                          ).toFixed(2) * 100
                                        : 0
                                    "
                                    :color="
                                      item_.useFlow / item_.totalFlow >= 0.9
                                        ? 'red'
                                        : item_.useFlow / item_.totalFlow >=
                                            0.8 &&
                                          item_.useFlow / item_.totalFlow < 0.9
                                        ? 'yellow'
                                        : tool.getThemeList(theme)
                                    "
                                    stroke-width="10"
                                  />
                                </div>

                                <div
                                  class="bottom-text"
                                  :class="{
                                    bottomText:
                                      item_.effectiveTime === null &&
                                      item_.deadTime === null
                                  }"
                                >
                                  <span v-if="item_.virtualPeriod">
                                      {{ item_.virtualPeriod }}
                                    </span>
                                  <span
                                    v-else-if="showEffectiveTimeShutDown(item_)"
                                    >{{ item_.effectiveTime.slice(0, 11) }} 到 {{
                                      item_.deadTime.slice(0, 11)
                                    }}
                                  </span>
																	<span v-if="item_.renewalStatus == 1">套餐正在受理中，请稍等</span>
                                  <span
                                    style="color: #f37f00"
                                    v-if="item_.totalVoice !== null && item_.renewalStatus != 1 && theme != 'AnFang'"
                                    >剩余{{
                                      item_.totalVoice - item_.useVoice
                                    }}分钟</span
                                  >
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- 停机保号套餐 -->
                      <div v-if="item.values.shutdownInsuranceNumberDetails" class="card-content">
                        <div
                          class="card-box"
                          v-for="(item_, index_) in item.values
                            .shutdownInsuranceNumberDetails"
                          :key="index_"
                        >
                          <div class="card-row">
                            <ul class="card-list">
                              <li>
                                <div class="top-text">
                                  <span>{{ item_.packageName }}</span>
                                </div>
                                <van-progress
                                  v-if="showProgressShutDown()"
                                  :percentage="0"
                                  color=""
                                  stroke-width="10"
                                />

                                <div
                                  class="bottom-text"
                                  :class="{
                                    bottomText:
                                      item_.effectiveTime === null &&
                                      item_.deadTime === null
                                  }"
                                >
                                <span v-if="item_.virtualPeriod">
                                      {{ item_.virtualPeriod }}
                                    </span>
                                  <span
                                    v-else-if="showEffectiveTimeShutDown(item_)"
                                    >{{ item_.effectiveTime.slice(0, 11) }} 到 {{
                                      item_.deadTime.slice(0, 11)
                                    }}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </van-collapse-item>
                  </van-collapse>
                </div>
              </div>
              <div v-else class="empty_list">
                暂无套餐~~~
              </div>
            </div>
            <!-- 用量详情 -->
            <div v-else>
              <div v-if="dataList_.length > 0">
                <div
                  class="card-item"
                  v-for="(item, index) in dataList_"
                  :key="index"
                >
                  <van-collapse
                    v-model="activeNames"
                    :accordion="true"
                    @change="parseData"
                  >
                    <van-collapse-item :name="index">
                      <template #title>
                        <div class="card-head__">
                          <h3>
                            <i class="icon cal"></i>{{ item.name.slice(6, 13) }}
                          </h3>
                          <div class="sub-text">
                            <div v-if="item.total !== null">
                              总用量:{{
                                item.total > 1024
                                  ? (item.total / 1024).toFixed(2) + "GB"
                                  : item.total + "MB"
                              }}
                            </div>
                            <div
                              v-if="
                                item.voiceTotal !== null && isTotalVoice !== ''
                              "
                            >
                              总语音:{{ item.voiceTotal }}分钟
                            </div>
                          </div>
                        </div>
                      </template>
                      <div
                        class="card-box unbox"
                        v-for="(item_, index_) in item.values"
                        :key="index_"
                      >
                        <ul class="cal-list">
                          <li>
                            <div>
                              <span>日期:</span>
                              <em>{{ item_.dayTime }}</em>
                            </div>
                            <div v-if="item_.flowUsed !== null">
                              <span>流量:</span>
                              <em>{{
                                item_.flowUsed > 1024
                                  ? (item_.flowUsed / 1024).toFixed(2) + "GB"
                                  : item_.flowUsed + "MB"
                              }}</em>
                            </div>
                            <div
                              v-if="
                                item_.voiceUsed !== null && isTotalVoice !== ''
                              "
                            >
                              <span>通话时长:</span>
                              <em>{{ item_.voiceUsed }}分钟</em>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </van-collapse-item>
                  </van-collapse>
                </div>
              </div>
              <div v-else class="empty_list">
                暂无用量详情~~~
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-container>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { queryCardPackage, queryCardUsedInfo } from "_API_/api.services";
import F2 from "@antv/f2";
export default {
  setup() {
    const state = reactive({
      userInfo:{labels:[]},
      theme: "", //主题名称
      iccid: "", //卡号
      activeName: "package", //类型展示
      activeNames: 0, //子类型展示
      dataList: [], // 套餐详情列表
      dataList_: [], // 用量详情列表
      chart: null,
      chartData: [],
      isTotalVoice: "", //是否展示语音
      flowStatus:'all'   //展示流量： used : 不展示总量和剩余 /  all ： 展示全部
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store } = ctx;
    const methods = {
      // 流量套餐有效期
      showEffectiveTimeFlow(item_){
        if(item_.effectiveTime !== null && item_.deadTime !== null && item_.renewalStatus != 1 && state.theme !== 'YuYin'){                                                                            
            if (state.theme == 'AnFang' && state.userInfo.labels.includes(4)) {
                return false
            }
            return true
        }
        return false
        },
      // 语音套餐有效期
      showEffectiveTimeVoice(item_){
        if (item_.effectiveTime !== null && item_.deadTime !== null && item_.renewalStatus != 1 && state.theme !== 'YuYin') {
            if (state.theme == 'AnFang' && state.userInfo.labels.includes(4)) {
                return false
            }
            return true                                                                 
        }
        return false
      },
      // 停机保号套餐有效期
      showEffectiveTimeShutDown(item_){
        if (item_.effectiveTime !== null && item_.deadTime !== null && item_.renewalStatus != 1 && state.theme !== 'YuYin') {                                                                            
            if (state.theme == 'AnFang' && state.userInfo.labels.includes(4)) {
                return false
            }
            return true                                                                 
        }
        return false
      },   
      // 进度条展示 【流量】
      showProgressFlow(item_){
        if(item_.totalFlow !== null && state.theme != 'AnFang'){
            return true
        }
        if(item_.totalFlow !== null && state.theme == 'AnFang'){
            if (state.userInfo.labels.includes(4)) {
                return false    
            }
            return true
        }
        return false
      },  
      // 进度条展示 【语音】
      showProgressVoice(item_){
        if(item_.totalVoice !== null && state.theme != 'AnFang'){
            return true
        }
        if(item_.totalVoice !== null && state.theme == 'AnFang'){
            if (state.userInfo.labels.includes(4)) {
                return false    
            }
            return true
        }
        return false
      },  
      // 进度条展示 【停机保号套餐】   
      showProgressShutDown(){
        if (state.theme == 'AnFang' && state.userInfo.labels.includes(4)) {
            return false
        }
        return true      
      },
      //获取数据
      getList(type) {
        switch (type) {
          case 1:
            // 套餐详情
            queryCardPackage(state.iccid).then(({ data: res }) => {
              if (res.code == 0) {
                state.dataList = res.data
                return
              }
              tool.toast(
                {
                  msg: res.msg,
                  duration: 1500
                },
                () => {}
              );
            })
            break
          case 2:
            // 用量详情
            queryCardUsedInfo(state.iccid).then(({ data: res }) => {
              if (res.code == 0) {
                state.dataList_ = res.data;
                if (state.dataList_.length > 0) {
                  state.activeNames = 0;
                  methods.parseData(state.activeNames);
                }
                return 
              }
              tool.toast(
                {
                  msg: res.msg,
                  duration: 1500
                },
                () => {}
              );
            });
            break;
          default:
            break;
        }
      },
      //类型选择
      selectType(name) {
        state.activeName = name;
        switch (state.activeName) {
          case "package":
            ctx.getList(1);
            break;
          case "dosage":
            ctx.getList(2);
            break;
          default:
            break;
        }
      },
      // 格式化图表
      parseData(val) {
        let dataList = state.dataList_;
        let key = "name",
          key1 = "total";
        let type = "month";
        if (String(val)) {
          dataList = state.dataList_[val].values;
          key = "dayTime";
          key1 = "flowUsed";
          type = "day";
        }
        let arr = [];
        dataList.map(item => {
          let obj = {
            date: key == "name" ? item[key].replace("month:", "") : item[key],
            value: item[key1],
            type: "流量"
          };
          arr.push(obj);
          // 如果数据中 有语音量 则显示语音
          if (
            dataList.find(item => {
              return (
                Number(item.voiceUsed) !== null && state.isTotalVoice !== ""
              );
            })
          ) {
            let obj1 = {
              date: key == "name" ? item[key].replace("month:", "") : item[key],
              value: Number(item.voiceUsed) || 0,
              type: "语音"
            };
            arr.push(obj1);
          }
          return false;
        });
        state.chartData = arr;
        methods.showChart(type);
      },
      // 绘制图表
      showChart(type = "month") {
        state.chart.clear();
        state.chart.source(state.chartData);
        state.chart.scale("date", {
          type: "timeCat",
          mask: type == "month" ? "MM" : "MM/DD",
          tickCount: parseInt(state.chartData / 3)
        });
        state.chart.axis("date", {
          label: function label(text, index, total) {
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = "left";
            } else if (index === total - 1) {
              textCfg.textAlign = "right";
            }
            if (index % 6 !== 0 && index != total - 1 && total > 7) {
              textCfg.text = "";
            }
            return textCfg;
          }
        });
        state.chart.tooltip({
          alwaysShow: true, // 当移出触发区域，是否仍显示提示框内容，默认为 false，移出触发区域 tooltip 消失，设置为 true 可以保证一直显示提示框内容
          offsetX: 0, // x 方向的偏移
          offsetY: 20, // y 方向的偏移
          triggerOn: ["touchstart", "touchmove"], // tooltip 出现的触发行为，可自定义，用法同 legend 的 triggerOn
          triggerOff: "touchend", // 消失的触发行为，可自定义
          showTitle: true, // 是否展示标题，默认不展示
          showCrosshairs: true, // 是否显示辅助线，点图、路径图、线图、面积图默认展示
          crosshairsStyle: {
            stroke: "rgba(0, 0, 0, 0.25)",
            lineWidth: 2
          }, // 配置辅助线的样式
          showTooltipMarker: true, // 是否显示 tooltipMarker
          tooltipMarkerStyle: {
            fill: "rgb(0,149,255,0.3)" // 设置 tooltipMarker 的样式
          },
          background: {
            radius: 2,
            fill: "rgb(0,149,255,0.3)"
          }
        });
        state.chart
          .line()
          .position("date*value")
          .color("type");
        state.chart.render();
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      //获取用户信息
      state.userInfo = store.state.user.userInfo;
      if(!state.userInfo.labels){
        state.userInfo.labels = []
      }
      //获取iccid卡号
      state.iccid = info.iccid;
      //获取是否语音卡
      state.isTotalVoice = info.totalVoice;
      ctx.getList(1);
      state.chart = new F2.Chart({
        id: "chartCanvas",
        pixelRatio: window.devicePixelRatio
      });
      state.flowStatus = window.SITE_CONFIG['flowStatus'] || 'all'
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  width: 48px;
  height: 48px;
}
.arrow {
  background: url("~_ASSETS_/image/record/arrow.png") center center/cover
    no-repeat;
  &.on {
    transform: rotate(180deg);
  }
}
.cal {
  background: url("~_ASSETS_/image/record/cal.png") center center/80% no-repeat;
}
// 套餐图片列表
$icons: (
  package_1: "effect.png",
  package_2: "effective.png",
  package_3: "expired.png",
	package_4: "renewal.png"
);
.setMeal_box {
  height: 100vh;
  .usage- {
    &record {
      /deep/ .van-tabs {
        height: 88px;
        line-height: 88px;
        .van-tab {
          font-size: 30px;
          &.van-tab--active {
            color: #2196f3;
          }
        }
        .van-tabs__line {
          background-color: transparent;
        }
      }
    }
    &box {
      padding: 30px;
      height: calc(100vh - 248px);
      overflow-y: auto;
      .card- {
        &item {
          background-color: #fff;
          border-radius: 20px;
          + .card-item {
            margin-top: 20px;
          }
        }
        &head {
          display: flex;
          padding: 20px;
          justify-content: space-between;
          border-bottom: 1px #f2f2f2 solid;
          h2 {
            color: #333;
            font-size: 30px;
            height: 46px;
            line-height: 46px;
            i {
              vertical-align: middle;
              margin-right: 4px;
              margin-bottom: 6px;
              @each $key, $png in $icons {
                &.#{$key} {
                  background: url("~_ASSETS_/image/record/#{$png}")
                    center
                    center/cover
                    no-repeat;
                }
              }
            }
          }
          .sub-text {
            display: flex;
            flex-direction: column;
            font-size: 26px;
            color: #aaa;
            i {
              vertical-align: middle;
            }
          }
        }
        &box {
          // padding: 20px 20px 0px;
          max-height: 780px;
          overflow-y: auto;
          .card- {
            &row {
              + .card-row {
                margin-top: 40px;
              }
              &-title {
                font-size: 26px;
                color: #333;
                margin-bottom: 20px;
              }
            }
            &list {
              li {
                + li {
                  margin-top: 30px;
                }
                div[class$="-text"] {
                  color: #777;
                  display: flex;
                  font-size: 26px;
                  justify-content: space-between;
                }
                /deep/ .van-progress {
                  margin: 10px 0;
                  .van-progress__pivot {
                    font-size: 0;
                  }
                }
                .top-text {
                  span {
                    &:first-child {
                      width: calc(100% - 200px);
                      display: inline-block;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                      word-break: break-word;
                    }
                  }
                }
                .validity-box {
                  padding-top: 10px;
                  font-size: 24px;
                  color: #999;
                }
                .bottom-text {
                  span {
                    &:first-child {
                      color: #333;
                    }
                  }
                }
                .bottomText {
                  display: flex;
                  justify-content: flex-end !important;
                }
              }
            }
          }
          &.unbox {
            padding: 0;
            .cal-list {
              li {
                border-bottom: 2px #f2f2f2 solid;
                padding-bottom: 20px;
                > div {
                  display: flex;
                  padding: 10px 20px;
                  font-size: 26px;
                  justify-content: space-between;
                  color: #777;
                  em {
                    color: #333;
                    font-style: normal;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .card-content {
    padding: 0px 20px;
    .card-box {
      padding-bottom: 20px;
      padding-top: 20px;
      border-bottom: 1px solid #ccc;
    }
    .card-box:last-child {
      border-bottom: 0px solid #ccc;
    }
  }
  #chartCanvas {
    display: block;
    width: 92vw !important;
    height: 400px !important;
    margin: 0 auto 20px;
    background: #fff;
  }

  .empty_list {
    font-size: 26px;
    color: #b2b2b2;
    padding: 80px;
    text-align: center;
  }
  .card-head_ {
    width: 220px;
    display: flex;
    font-size: 26px;
    i {
      vertical-align: middle;
      margin-right: 4px;
      margin-bottom: 6px;
      @each $key, $png in $icons {
        &.#{$key} {
          background: url("~_ASSETS_/image/record/#{$png}")
            center
            center/cover
            no-repeat;
        }
      }
    }
  }
  .card-head__ {
    width: 600px;
    display: flex;
    font-size: 26px;
    justify-content: space-between;
    i {
      vertical-align: middle;
      margin-right: 4px;
      margin-bottom: 6px;
      @each $key, $png in $icons {
        &.#{$key} {
          background: url("~_ASSETS_/image/record/#{$png}")
            center
            center/cover
            no-repeat;
        }
      }
    }
  }
}
</style>
